<template>
  <div>
    <modal :show="isModalShow" :showClose="false">
      <template slot="header">
        <h5 class="modal-title">{{ $t("confirm.delete.closingDays") }}</h5>
      </template>
      <template slot="footer">
        <base-button type="secondary" @click="isModalShow = false">{{
            $t("cancel")
          }}</base-button>
        <base-button type="danger" :disabled="isDeleting" @click="deleteClosingDays">
          {{ $t("delete") }}
        </base-button>
      </template>
    </modal>

    <div class="row">
      <div class="col-12">
        <card>
          <div class="col d-flex flex-column-reverse flex-sm-row  justify-content-sm-between align-items-sm-center mb-4">
            <div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="box circle-red mr-2"></div>
                <span>{{ $t("closingDays") }}</span>
              </div>
            </div>
            <div class="d-block">
              <base-button
                  :disabled="isDeleteButtonDisabled"
                  type="danger"
                  class="mr-2"
                  @click="isModalShow = true"
              >{{ $t("delete") }}</base-button
              >
              <base-button
                  :disabled="isAddButtonDisabled"
                  type="success"
                  @click="addClosingDays"
              >{{ $t("add") }}</base-button
              >
            </div>
          </div>

          <div class="col">
            <Calendar
                :value="null"
                color="green"
                :min-date="new Date()"
                :attributes="attrs"
                :columns="layout.columns"
                :rows="layout.rows"
                :is-expanded="layout.isExpanded"
                :locale="locale"
                @dayclick="onDayClick"
                @update:from-page="onPageChangeClick"
            ></Calendar>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Calendar } from "v-calendar";
import { Card, BaseButton, Modal } from "@/components/index";
import { DateTime, Settings } from 'luxon';
import {mapState} from "vuex";

export default {
  name: "StoreClosingDays",
  data() {
    return {
      routeList: "/stores/closing-days",
      route: "/stores/days-off",
      closingDays: [],
      date: null,
      attrs: [
        {
          highlight: "red",
          dates: [],
        },
        {
          highlight: "green",
          dates: [],
        },
        {
          highlight: "yellow",
          dates: [],
        },
      ],
      isDeleteButtonDisabled: true,
      isAddButtonDisabled: true,
      daySelected: null,
      days: [],
      startDate: DateTime.now().startOf("month").toFormat("yyyy-LL-dd"),
      endDate:   DateTime.now().plus({ months: 5 }).endOf("month").toFormat("yyyy-LL-dd"),
      isModalShow: false,
      isDeleting: false,
    };
  },
  computed: {
    layout() {
      return this.$screens(
          {
            default: {
              columns: 1,
              rows: 1,
              isExpanded: true,
            },
            lg: {
              columns: 2,
              rows: 2,
              isExpanded: true,
            },
            xl: {
              columns: 3,
              rows: 2,
              isExpanded: true,
            }
          },
      );
    },
    ...mapState(["locale"]),
  },
  methods: {
    async getClosingDays() {
      const route = `${this.routeList}?start_date=${this.startDate}&end_date=${this.endDate}`;
      let response = await this.axios.get(route);

      if (response && response.data.status === "success") {
       this.closingDays = response.data.data.stores;

       this.attrs[0].dates = this.closingDays.map(day => {return day.datetime});
      }
    },
    async addClosingDays() {
      this.isAddButtonDisabled = true;
      const route = `${this.route}`;
      let response = null;
      for (const date of this.attrs[1].dates) {
        response = await this.axios.post(route, {datetime: date});
      }
      if (response && response.data.status === "success") {
        await this.getClosingDays();
        this.attrs[1].dates = [];
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async deleteClosingDays() {
      this.isDeleting = true;
      this.isDeleteButtonDisabled = true;
      const route = `${this.route}`;
      let response = null;
      for (const date of this.attrs[2].dates) {
        for (const day of this.closingDays) {
          if (day.datetime === date) {
            response = await this.axios.delete(`${route}/${day.id}`);
          }
        }
      }
      if (response && response.data.status === "success") {
        this.isModalShow = false;
        await this.getClosingDays();
        this.attrs[2].dates = [];
        this.$toast.success(this.$t("notifications.success.general"));
      }
      this.isDeleting = false;
    },
    onDayClick(event) {
      if (event.isDisabled) return;

      const exists = this.closingDays.some((day) => {
        return day.datetime === event.id;
      });

      if (exists && !event.isDisabled) {
        const index = this.attrs[2].dates.indexOf(event.id);
        if (index >= 0) {
          if (this.attrs[0].dates.indexOf(event.id) >= 0) {
            this.attrs[2].dates.splice(index, 1);
          }
        } else {
          if (this.attrs[0].dates.indexOf(event.id) >= 0) {
            this.attrs[2].dates.push(event.id);
          }
        }

        if (this.attrs[2].dates.length > 0) {
          this.isDeleteButtonDisabled = false;
        }
        if (!this.attrs[2].dates.length > 0) {
          this.isDeleteButtonDisabled = true;
        }
      }

      if (!exists && !event.isDisabled) {
        const index = this.attrs[1].dates.indexOf(event.id);
        if (index >= 0) {
          if (this.attrs[0].dates.indexOf(event.id) < 0) {
            this.attrs[1].dates.splice(index, 1);
          }
        } else {
          if (this.attrs[0].dates.indexOf(event.id) < 0) {
            this.attrs[1].dates.push(event.id);
          }
        }

        if (this.attrs[1].dates.length > 0) {
          this.isAddButtonDisabled = false;
        } else {
          this.isAddButtonDisabled = true;
        }
      }
    },
    onPageChangeClick(event) {
      const dateTime    = DateTime.fromObject({ year: event.year, month: event.month, day: 1 })
      this.startDate    = dateTime.toFormat("yyyy-LL-dd");
      this.endDate      = dateTime.plus({ months: 5 }).endOf("month").toFormat("yyyy-LL-dd");      
      this.getClosingDays();
    },
  },
  components: {
    Calendar,
    Card,
    BaseButton,
    Modal,
  },
}
</script>

<style scoped>
.buttonContainer {
  display: flex;
  justify-content: space-between;
}
.cardContent {
  padding: 1.5rem;
}
.box {
  display: inline-block;
  height: 12px;
  width: 12px;
  border: 2px solid;
  border-radius: 6px;
}
.circle-red {
  background-color: #E53E3E;
  color: white;
}
</style>

<style>
.vc-pane-layout {
  gap: 2.5rem;
}
</style>