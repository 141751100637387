<template>
  <div>
    <modal :show="isModalShow" :showClose="false">
      <template slot="header">
        <h5 class="modal-title">{{ $t("confirm.delete.image") }}</h5>
      </template>
      <template slot="footer">
        <base-button type="secondary" @click="isModalShow = false">{{
      $t("cancel")
    }}</base-button>
        <base-button :disabled="isBtnDeleteImageDisabled" type="danger" @click="deleteItem">{{
      $t("delete")
    }}</base-button>
      </template>
    </modal>
    <div class="row">
      <div class="col-12">
        <card class="minHeight">
          <form v-if="store" @change="disabledBtn(false)" @submit.prevent="updateItem()">
            <section class="d-sm-flex">
              <div
                class="col-12 col-sm-8 d-flex justify-content-center justify-content-sm-start mb-2 mt-2 mt-sm-0 mb-sm-0">
                <img v-if="!isPrintingPdf" @click="printPdf()" src="../../assets/img/qrcode.svg" :alt="$t('alt.qrCode')"
                  class="pointer imgQr" width="50rem" height="50rem">
                <Spinner v-else class="imgQr"></Spinner>
                <div class="d-flex align-items-center pl-2 col-8">
                  <span>{{ $t("qrBtnInfo") }}</span>
                </div>
              </div>
              <div v-if="isManager || isAdmin" class="d-flex justify-content-end col-12 col-sm-4">
                <base-button :disabled="isBtnDisabled" native-type="submit" type="success">
                  {{ $t("save") }}
                </base-button>
              </div>
            </section>
            <hr />
            <div v-if="!isSpinnerShow" class="col py-4">
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-input max="255" v-model="store.name" :placeholder="$t('name')" :label="`${$t('name')}*`"
                    required></base-input>
                </div>
                <div class="col-12 col-md-6">
                  <base-input max="255" v-model="store.phone" :placeholder="$t('phone')"
                    :label="`${$t('contactPhone')}*`" required></base-input>
                </div>
                <div class="col-12 col-md-12">
                  <input-places-autocomplete v-model="store.address" label="address" v-on:place-selected="placeSelected"
                    @valueChange="placeSelected" required>
                  </input-places-autocomplete>
                </div>
                <div class="col-12 col-md-3 order-md-2">
                  <base-input inputType="number" inputmode="numeric" pattern="[0-9]*" max="240" min="10"
                    v-model="store.appointment_gap" :placeholder="$t('appointmentGap')"
                    :label="`${$t('appointmentGap')}*`" :btnHelp="true"
                    :btnHelpDescription="`${$t('appointmentGapHelp')}`" required></base-input>
                </div>
                <div class="col-12 col-md-3 order-md-2">
                  <base-input inputType="number" inputmode="numeric" pattern="[0-9]*" max="1200" min="0"
                    v-model="store.time_restriction" :placeholder="$t('timeRestriction')"
                    :label="`${$t('timeRestriction')}*`" :btnHelp="true"
                    :btnHelpDescription="`${$t('timeRestrictionHelp')}`" required></base-input>
                </div>
                <div class="col-12 col-md-6 order-md-1 mb-4">
                  <div>
                    <label class="control-label">
                      {{ $tc("category", 2) }}
                    </label>
                  </div>
                  <Select multiple class="select-primary w-100" :placeholder="$t('select.categories')"
                    v-model="selectedCategories" @change="disabledBtn(false)">
                    <Option v-for="categorie in storeCategories" class="select-primary" :value="categorie.code"
                      :label="categorie.code" :key="categorie.id">
                    </Option>
                  </Select>
                </div>
                <div class="col-12 col-md-6 order-md-2">
                  <google-maps :longitude="store.longitude" :latitude="store.latitude" :address="store.address"
                    @place-selected="placeSelected"></google-maps>
                </div>
              </div>
              <div class="d-flex align-items-center mb-4">
                <base-checkbox v-model="store.display_in_listing" class="mb-2" :disabled="!store.premium">
                  <div v-html="$t('displayInListing')"></div>
                </base-checkbox>
              </div>

              <div class="row">
                <div class="col-12 col-md-6">
                  <label class="control-label mt-4 mt-md-0">{{
      $t("logo")
    }}</label>
                  <image-upload id="inputLogo" ref="inputLogo" @change="onImageChange($event, 'logo')" :src="logo"
                    :changeText="$t('change')" :removeText="$t('delete')" :select-text="$t('select.picture')"
                    altImage="logoStore" />
                </div>
                <div class="col-12 col-md-6">
                  <label class="control-label mt-4 mt-md-0">{{
      $t("picture")
    }}</label>
                  <image-upload id="inputMainPicture" ref="inputMainPicture" @change="onImageChange($event, 'main')"
                    :src="mainPicture" :changeText="$t('change')" :removeText="$t('delete')"
                    :select-text="$t('select.picture')" altImage="service" />
                </div>
              </div>

              <div class="row pt-md-4">
                <div class="col-12">
                  <div>
                    <label class="control-label mt-4 mt-md-0">{{
      $t("gallery")
    }}</label>
                  </div>
                  <div v-if="images.length > 0">
                    <div v-for="(image, i) in images" :key="i" class="containerImage">
                      <img class="image pointer" :src="image" @click="onClick(i)" :alt="$t('alt.galleryImage')" />
                      <span class="deleteButton el-icon-delete pointer" @click="showModalDelete($event)"></span>
                    </div>
                    <vue-gallery-slideshow :images="images" :index="index"
                      @close="index = null"></vue-gallery-slideshow>
                  </div>
                  <image-upload-multiple id="inputGallery" ref="ImageUploadMultiple"
                    @change="onImageChange($event, 'gallery')" :src="images" :isSaved="isSaved" />
                </div>
              </div>

              <div class="row" style="display: none">
                <div class="col-12 col-md-6 col-lg-4">
                  <base-input :label="`${$t('latitude')}`" :value="store.latitude"></base-input>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <base-input :label="`${$t('longitude')}`" :value="store.longitude"></base-input>
                </div>
              </div>
            </div>
            <Spinner v-else parentClasses="justify-content-center"></Spinner>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Card,
  BaseButton,
  BaseInput,
  ImageUpload,
  ImageUploadMultiple,
  InputPlacesAutocomplete,
  GoogleMaps,
  Modal,
  Spinner,
} from "@/components/index";
import { mapActions, mapState } from "vuex";
import VueGallerySlideshow from "vue-gallery-slideshow";
import { Select, Option } from "element-ui";
import FileSaver from 'file-saver'

export default {
  name: "StoreEdit",
  props: ["store", "storeCategories", "images", "logo", "mainPicture", "selectedStoreCategories", "isSpinnerShow"],
  data() {
    return {
      route: "/stores",
      isBtnDisabled: true,
      isBtnDeleteImageDisabled: false,
      isSaved: false,
      isPrintingPdf: false,
      inputAddress: null,
      index: null,
      inputFiles: ["inputLogo", "inputMainPicture", "inputGallery"],
      isModalShow: false,
      storeId: null,
      newSchedules: [],
      deleteSchedules: [],
      storeSchedules: [],
      selectedCategories: [],
    };
  },
  computed: {
    ...mapState(["isManager", "isAdmin"]),
  },
  watch: {
    selectedStoreCategories(newValue) {
      this.selectedCategories = newValue;
    }
  },
  methods: {
    async updateItem() {
      this.disabledBtn(true);
      const route = `${this.route}/${this.store.id}`;

      const storeFormData = new FormData();
      for (const key in this.store) {
        if (key === "display_in_listing" && this.store.premium == 1) {
          this.store.display_in_listing = (this.store.display_in_listing === true) ? 1 : 0;
        }
        if (key === "gallery") {
          this.store[key].forEach((file) => storeFormData.append("gallery[]", file));
          break;
        }
        if (key === "store_categories") {
          if (!this.selectedCategories.length > 0) {
            this.$toast.error(this.$t("notifications.error.noCategory"));
            return;
          }
          const categoriesUpdate = [];
          this.storeCategories.forEach((item) => {
            this.selectedCategories.find((code) => {
              if (code === item.code) categoriesUpdate.push(item);
            });
          });
          categoriesUpdate.forEach((category, index) => {
            storeFormData.append(`categories[${index}]`, category.id);
          });
        }
        if (key !== "premium") {          
          storeFormData.append(key, this.store[key]);
        }          
      }
      storeFormData.append("_method", "PUT");
      let response = await this.axios.post(route, storeFormData);
      if (response && response.data.status === "success") {
        this.$refs?.inputMainPicture?.cleanInput();
        this.$refs?.inputLogo?.cleanInput();
        this.$refs?.ImageUploadMultiple?.cleanInput();
        this.$toast.success(this.$t("notifications.success.general"));
        this.$emit("get-store");
        this.isSaved = true;
      }
    },
    placeSelected(place) {
      if (place !== null) {
        if (place.address) this.store.address = place.address;
        if (place.country) this.store.country = place.country;
        if (place.locality) this.store.locality = place.locality;
        if (place.administrative_area_level_1) this.store.administrative_area_level_1 = place.administrative_area_level_1;
        if (place.administrative_area_level_2) this.store.administrative_area_level_2 = place.administrative_area_level_2;
        if (typeof place.lng === "number" && typeof place.lat === "number") {
          this.store.longitude = place.lng;
          this.store.latitude = place.lat;
          this.disabledBtn(false);
        }
      }
    },
    onImageChange(file, type) {
      if (file) {
        this.disabledBtn(false);
        if (this.isSaved) this.isSaved = false;
        if (type === "gallery") {
          const files = Object.values(file);
          this.store[type] = files;
          return;
        }
        this.store[type] = file;
      } else {
        if (type !== 'gallery') delete this.store[type];
      }
    },
    disabledBtn(disabled) {
      this.isBtnDisabled = disabled;
      this.$store.dispatch('setHasChangeForm', !disabled)
    },
    onClick(i) {
      this.index = i;
    },
    showModalDelete(e) {
      this.isModalShow = true;
      this.pictureSelected = e.target.previousSibling.currentSrc;
    },
    async deleteItem() {
      this.isBtnDeleteImageDisabled = true;
      const pictureToDelete = this.store.pictures.find(
        (picture) => picture.url === this.pictureSelected
      );
      const route = `${this.route}/${this.storeId}/picture/${pictureToDelete.id}`;

      let response = await this.axios.delete(route);
      if (response && response.data.status === "success") {
        this.$toast.success(this.$t("notifications.success.general"));
        this.isModalShow = false;
        this.$emit("get-store");
        this.isBtnDeleteImageDisabled = false;
      }
    },
    async printPdf() {
      this.isPrintingPdf = true;
      const route = `${this.route}/qr-pdf`;
      let response = await this.axios.get(route);

      if (response && response.data.status === "success") {
        const byteArr = this.convertbase64toArrayBuffer(response.data.data.pdf);
        const blob = new Blob([byteArr], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `QRCode ${this.store.name}` + '.pdf');
      }

      this.isPrintingPdf = false;
    },
    convertbase64toArrayBuffer(pdf) {
      const binary_string = window.atob(pdf);
      const len = binary_string.length;
      let bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    ...mapActions(["setHasChangeForm"])
  },
  mounted() {        
    this.storeId = this.store.id;
  },
  components: {
    Card,
    BaseButton,
    BaseInput,
    ImageUpload,
    ImageUploadMultiple,
    InputPlacesAutocomplete,
    GoogleMaps,
    VueGallerySlideshow,
    Modal,
    Select,
    Option,
    Spinner,
  },
};
</script>

<style scoped>
.minHeight {
  min-height: 90vh !important;
}

.image {
  width: 120px;
  height: 120px;
  background-size: contain;
  margin: 10px;
  border-radius: 3px;
}

.pointer {
  cursor: pointer;
}

.deleteButton {
  align-items: center;
  border-radius: 0.5rem;
  color: red;
  display: flex;
  font-size: 17px;
  font-weight: bold;
  height: 2rem;
  justify-content: center;
  margin: 0 auto 15px;
  width: 2rem;
}

.deleteButton:hover {
  background: rgba(255 0 0 / 50%);
  color: white;
}

.containerImage {
  display: inline-block;
}

@media (max-width: 600px) {
  .imgQr {
    height: 50px;
  }
}
</style>
