<template>
  <div class="content">
    <tabs>
      <tab :name="$tc('edit', 2)" :selected="true">
        <StoreEdit
            :store="store"
            :store-categories="storeCategories"
            :images="images"
            :logo="logo"
            :main-picture="mainPicture"
            :selected-store-categories="selectedStoreCategories"
            :is-spinner-show="isSpinnerShow"
            @get-store="getStore"
        ></StoreEdit>
      </tab>
      <tab v-if="isManager" :name="$tc('closingDays', 2)">
        <StoreClosingDays/>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { Tabs, Tab } from "../../components";
import StoreEdit from "./StoreEdit";
import StoreClosingDays from "./StoreClosingDays";
import {mapState} from "vuex";

export default {
  name: "StoreManagement",
  data() {
    return {
      store: {},
      route: "/stores",
      isBtnDisabled: true,
      inputAddress: null,
      images: [],
      index: null,
      logo: "img/img-default.fb00fe14.png",
      mainPicture: "img/img-default.fb00fe14.png",
      inputFiles: ["inputLogo", "inputMainPicture", "inputGallery"],
      isModalShow: false,
      storeId: null,
      storeCategories: [],
      selectedStoreCategories: [],
      isSpinnerShow: true,
    };
  },
  computed: {
    ...mapState(["isManager", "isAdmin", "defaultTitle"]),
  },
  methods: {
    async getStore() {
      const route = `${this.route}/${this.storeId}`;
      let response = await this.axios.get(route);

      if (response && response.data.status === "success") {
        this.images = [];
        this.loading = false;
        this.store = response.data.data.stores;
        this.store.pictures.forEach((picture) => {
          picture.type === "3"
            ? this.images.push(picture.url)
            : picture.type === "2"
            ? (this.mainPicture = picture.url)
            : (this.logo = picture.url);
        });
        this.selectedStoreCategories = [];
        this.store.store_categories.forEach((item) => {
          this.selectedStoreCategories.push(item.code);
        });
        this.isSpinnerShow = false;
      }
    },
    async getStoreCategories() {
      const route = `store-categories`;
      let response = await this.axios.get(route);

      if (response && response.data.status === "success") {
        this.storeCategories = response.data.data.store_categories.data;
      }
    }
  },
  beforeMount() {    
    const user = JSON.parse(localStorage.getItem("user"));    
    this.storeId = (user.store) ? user.store.id : this.$route.params.id  ;
    if (this.id === null) {;
      this.isSpinnerShow = false;
    }
  },
  mounted() {
    if (!this.isManager && !this.isAdmin) this.$router.push("/");
    this.getStore();
    this.getStoreCategories();
  },
  metaInfo() {
    return { title: `${this.$t('routes.storeManagement')} - ${this.defaultTitle}` }
  },
  components: {
    Tabs,
    Tab,
    StoreEdit,
    StoreClosingDays,
  },
};
</script>